import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
import React from 'react';
import PropTypes from 'prop-types';
import TagPage from '@fuww/library/src/Layouts/TagPage';
import lowercase from 'lodash/lowerCase';
import { useIntl } from 'react-intl';
import Head from '../components/Head';
import HomeLink from '../components/HomeLink';
import tagQuery from '../lib/queries/tags/tagQuery.graphql';
import throw404 from '../lib/throw404';
import withQuery from '../lib/withQuery';
import messages from '../lib/messages.mjs';
import LinkAndAnchor from '../components/LinkAndAnchor';
import { useSiteContext } from '../components/SiteContext';
import tagArticles from '../lib/queries/tags/tagArticles.graphql';
import getNewsboardFieldVariables
from '../lib/queries/getNewsboardFieldVariables';
import getConnectionField from '../lib/queries/getConnectionField';
import withApollo from '../lib/apollo';
import { articleListImageConfigurations } from '../lib/imageConfigurations';
import loading from '../lib/loading';

const ArticlesWithQuery = dynamic(() => import('../components/Special/News'), {
  ssr: true,
  loading,
});

const LIMIT = 12;

const Tag = ({
  data: { tag },
  children,
}) => {
  const intl = useIntl();
  if (!tag) return throw404();
  const { title, slug } = tag;

  const breadcrumbs = [
    <HomeLink />,
    <LinkAndAnchor
      route="tags"
      passHref
    >
      Tags
    </LinkAndAnchor>,
    <LinkAndAnchor
      route="tag"
      passHref
      params={{ slug }}
    >
      {title}
    </LinkAndAnchor>,
  ];

  return (
    <>
      <Head
        title={`${intl.formatMessage(
          messages.tagTitle, { title },
        )}`}
        description={`${intl.formatMessage(
          messages.tagMetaDescription, { title },
        )}`}
        subjectType="Tag"
        subjectId={slug}
      />
      <TagPage
        breadcrumbs={breadcrumbs}
        title={`${intl.formatMessage(
          messages.tagTitle, { title },
        )}`}
      >
        {children}
      </TagPage>
    </>
  );
};

Tag.propTypes = {
  data: PropTypes.shape({
    tag: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
};

Tag.defaultProps = {
  data: {},
  children: undefined,
};

const TagWithQuery = withQuery(Tag);

const TagArticlesPage = ({
  router: { query: { slug } },
}) => {
  const { prefix, hasLocalNews, newsboard } = useSiteContext();

  const articlesField = getConnectionField(prefix, hasLocalNews);
  const tagConnectionVariables = {
    ...getNewsboardFieldVariables(prefix, hasLocalNews),
    slug,
    first: LIMIT,
    newsboards: [newsboard],
    imageConfigurations: articleListImageConfigurations,
  };

  return (
    <TagWithQuery
      query={tagQuery}
      variables={{ slug }}
      errorMessage={`Error loading ${lowercase(slug)} news articles`}
    >
      <ArticlesWithQuery
        query={tagArticles}
        articlesField={articlesField}
        variables={tagConnectionVariables}
        errorMessage="Error loading news articles"
        preloadFirst
      />
    </TagWithQuery>
  );
};

TagArticlesPage.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.shape({
      slug: PropTypes.string,
    }),
  }),
};

TagArticlesPage.defaultProps = {
  router: {},
};

export default withApollo(withRouter(TagArticlesPage));
